<template>
  <Modal emarket-modal>
    <div class="emarket-content">
      <div class="market-inner">
        <h1>HCORE</h1>
        <p>
          프리미엄 건설용 강재, <br class="mo">
          <b>현대제철 H CORE</b>를<br>
          온라인에서 편하게 구매하실 수 있는<br class="mo">
          H CORE 스토어를 준비 중입니다.<br>
          조금만 기다려 주세요!
        </p>
      </div>
    </div>
    <a class="close" @click="$emit('resolve')">닫기</a>
  </Modal>
</template>

<script>
import Modal from "@/views/components/modal/Modal";
export default {
  name: "EmarketModal",
  components: {Modal},
  props: ['options'],
  data() {
    return {
    }
  },
  methods: {}
}
</script>

<style lang="less">
@import "~@/less/proj";
@use-rem: true;
@rem: 32;
[emarket-modal] {
  .emarket-content { .w(80%); .bgc(#fff); .p(20); .-box; .tc; .mh-c; .max-w(600);
    .market-inner { .-a(#e5e5e5,3); .wf; .p(43,0,60); .-box;
      h1 { .wh(102,75); .contain('/images/mo/e-logo.png'); .mh-c; }
      p { .fs(30); .lh(48); .spoqa; .ls(-0.025em); .mt(45); }
    }
  }
}

@media screen and(min-width:1024px) {
  [emarket-modal] {
    .inner { width: auto!important;
      .emarket-content { .w(700); .p(10); .max-w(700);
        .market-inner { .p(29,0,40);
          h1 { .wh(76,55); .contain('/images/pc/e-logo.png'); .mh-c; }
          p { .fs(20); .lh(30); .mt(25); }
        }
      }
    }
  }
}
</style>